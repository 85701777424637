import axios from 'axios';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import '../pages/styles/Popup.css';

function PopupButton() {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nomber, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState(""); 

  useEffect(() => {
    const hasSubmitted = localStorage.getItem('formSubmitted');
    if (hasSubmitted !== 'true') {
            setShow(true);
          }
  }, []);
  const handleClose = () => {
    setShow(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, email, nomber, subject} ;
    try {
      console.log(data, 'function clicked');
      const response = await axios.post('https://memberpanel.defencehousingsociety.com/submitPopupData', data);
      setFeedbackMessage(response.data.message);
      localStorage.setItem('formSubmitted', 'true');
      setTimeout(() => {
        handleClose();
      }, 2000) ; 
    } catch (error) {
      setFeedbackMessage("Failed to send details. Please try again.");
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
         <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
  <Modal.Header style={{ paddingLeft: '55px', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'linear-gradient(105deg, #6e99e6, #093c94)' }}>
    <Modal.Title style={{ fontSize: '26px' }}>Book your site visit today.</Modal.Title>
    <button type="button" style={{ marginLeft: 'auto' }} className="custom-close-btn" onClick={handleClose}></button>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Your Name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Your Email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Your Number"
          value={nomber}
          onChange={(e) =>setNumber(e.target.value)}
          autoFocus
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type='text'
          value={subject}
          onChange={(e) =>setSubject(e.target.value)}
          as="textarea"
          rows={1}
        />
      </Form.Group>
    </Form>
  </Modal.Body>
  {feedbackMessage && (
    <div style={{ textAlign: 'center', marginTop: '6px', color: 'green' }}>
      {feedbackMessage}
    </div>
  )}
  <Button style={{ background: '#24457b', color: 'white' }} variant="primary" onClick={handleSubmit}>
    Submit
  </Button>
</Modal>
    </>
  );
}
export default PopupButton;



// import axios from 'axios';
// import { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import '../pages/styles/Popup.css'

// function PopupButton() {
//   const [show, setShow] = useState(false);
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [nomber, setNumber] = useState("");
//   const [subject, setSubject] = useState("");
//   const [feedbackMessage, setFeedbackMessage] = useState(""); 


//   useEffect(() => {
//     const hasSubmitted = localStorage.getItem('formSubmitted');
//     console.log(hasSubmitted, 'submitted or not submitted');
//     console.log(hasSubmitted,'hasSubmitted or not submitted');
    
//     if (hasSubmitted !== 'true') {
//       setShow(true);
//     }
//   }, []);
//   const handleClose = () => {
//     setShow(false);
//     setTimeout(() => {
//       setShow(true)
//     }, 1000);
//   }


//   const handleSubmit = async (e) => {
//     e.preventDefault(); 
//     const data = { name, email, nomber, subject };
  
//     try {
//       console.log(data, 'function clicked');
//       const response = await axios.post('http://localhost:5000/submitPopupData', data);
//       // const response = await axios.post('https://memberpanel.defencehousingsociety.com/submitPopupData', data);
      
//       // Set feedback message and store the submission status
//       setFeedbackMessage(response.data.message);
//       localStorage.setItem('formSubmitted', 'true');
  
//       // Log localStorage data after storing it
//       const submittedStatus = localStorage.getItem('formSubmitted');
//       console.log(submittedStatus, 'Form submission status in localStorage');  // Should log "true"
      
//       // Close modal after short delay
//       setTimeout(() => {
//         handleClose();
//       }, 1000);
//     } catch (error) {
//       // Handle error response
//       setFeedbackMessage("Failed to send details. Please try again.");
//       console.error("Error submitting form:", error);
//     }
//   };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault(); 
  //   const data = { name, email, nomber, subject };

  //   try {
  //     console.log(data, 'function clicked');
  //     const response = await axios.post('http://localhost:5000/submitPopupData', data);
  //     // const response = await axios.post('https://memberpanel.defencehousingsociety.com/submitPopupData', data);
  //     setFeedbackMessage(response.data.message) ;
  //      localStorage.setItem('formSubmitted', 'true');
  //     setTimeout(() => {
  //       handleClose();
  //     }, 1000); 
  //   } catch (error) {
  //     // Handle error response
  //     setFeedbackMessage("Failed to send details. Please try again.");
  //     console.error("Error submitting form:", error);
  //   }
  // };
//   return (
//     <>
//     <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
//   <Modal.Header style={{ paddingLeft: '55px', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'linear-gradient(105deg, #6e99e6, #093c94)' }}>
//     <Modal.Title style={{ fontSize: '26px' }}>Book your site visit today.</Modal.Title>
//     <button type="button" style={{ marginLeft: 'auto' }} className="custom-close-btn" onClick={handleClose}></button>
//   </Modal.Header>
//   <Modal.Body>
//     <Form>
//       <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
//         <Form.Label>Name</Form.Label>
//         <Form.Control
//           type="text"
//           placeholder="Enter Your Name"
//           autoFocus
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />
//         <Form.Label>Email address</Form.Label>
//         <Form.Control
//           type="email"
//           placeholder="Enter Your Email"
//           autoFocus
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <Form.Label>Mobile Number</Form.Label>
//         <Form.Control
//           type="text"
//           placeholder="Enter Your Number"
//           value={nomber}
//           onChange={(e) =>setNumber(e.target.value)}
//           autoFocus
//         />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
//         <Form.Label>Subject</Form.Label>
//         <Form.Control
//           type='text'
//           value={subject}
//           onChange={(e) =>setSubject(e.target.value)}
//           as="textarea"
//           rows={1}
//         />
//       </Form.Group>
//     </Form>
//   </Modal.Body>
//   {feedbackMessage && (
//     <div style={{ textAlign: 'center', marginTop: '6px', color: 'green' }}>
//       {feedbackMessage}
//     </div>
//   )}
//   <Button style={{ background: '#24457b', color: 'white' }} variant="primary" onClick={handleSubmit}>
//     Submit
//   </Button>
// </Modal>
//     </>
//   );
// }

// export default PopupButton;
